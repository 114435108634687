﻿<script>
	
	import { onMount } from 'svelte';
	import EugGrid from './shared/EugGrid.svelte';
	import { SiteSettings, showSpinner } from '../shared/store';
	import { Pick5GetTeams, SavePick5, GetUserPick5Entries, GetPick5Results } from '../shared/utils';
	import { Spinner } from 'sveltestrap';
	import Modal from './shared/Modal.svelte';
	import Swal from 'sweetalert2';
	import Fa from 'svelte-fa';
	import { faChevronDown, faChevronUp, faInfo } from '@fortawesome/free-solid-svg-icons';

	export let league;
	//let season = new Date().getFullYear(); //todo: figure this out for history
	let Mode = "";

	let PickFromTeams = [];
	let EntryList = []; //used for more than 1 entry per user
	let SelectedEntry = 1
	let Bracket1 = [];
	let Bracket2 = [];
	let Bracket3 = [];
	let Bracket4 = [];
	let Bracket5 = [];
	let selectedTeams = [];
	let has5Selected = false;
	let showBtnSpinner = false;
	let showPick5 = false;
	let showRules = false;

	let standings = [];
	let showTeamList = [];

	let pickCols = [{Name: 'ID', Hidden: true}, {Name: 'Team'}];

	let gridActions = { "Actions": [] };
	function AddActions() {
		gridActions.Actions.push({ "ColName": "Pick", "Type": "Radio", "GroupName": getGroupName, "Checked": isChecked, "Function": checkTeam, "Return": "ROW", "RowKey": "ID" });
	}

	function isChecked(action, returnData, rowIndx) {
		if (selectedTeams.find(x => x.ID == returnData.ID))
			return true;

		return false;
	};

	function getGroupName(rowData) {
		return 'Bracket' + rowData.Bracket;
	};

	//Example of a Button Action - Keep for reference
	function checkTeam(action, returnData, rowIndx) {
		var selTeam	= {};

		selTeam = PickFromTeams.find(x => x.ID == returnData.ID);

		var oldBraacketTeam = selectedTeams.find(x => x.Bracket == selTeam.Bracket);
		if (oldBraacketTeam)	
			selectedTeams = selectedTeams.filter(x => x.ID != oldBraacketTeam.ID);

		if (selectedTeams.find(x => x.ID == returnData.ID)) {
			selectedTeams = selectedTeams.filter(x => x.ID != returnData.ID);
		}
		else
			selectedTeams.push(selTeam);

		has5Selected = selectedTeams.length == 5;
	};

	async function savePicks() {
		showSpinner.set(true);
		await SavePick5(league, SelectedEntry, selectedTeams);
		showSpinner.set(false);

		Swal.fire({
			text: "Picks Saved!",
            icon: 'info',
            color: 'var(--bs-body-color)',
            background: 'var(--bs-dark)',
            confirmButtonColor: 'var(--bs-primary)',
		})
	}

	function selectUserPicksByEntry() {
		for(var x = 1; x <= 5; x++)
		{
			var selTeam = EntryList[SelectedEntry - 1].Picks.filter(team => team.Bracket == x)[0];
			selectedTeams.push(PickFromTeams.find(x => x.ID == selTeam.ID));
		}
	}

	onMount(async () => {
		showSpinner.set(true);
		if (league == "MLB")
			Mode = $SiteSettings.MLB.Games[0].Props.Mode;
		else
			Mode = $SiteSettings.NFL.Games[0].Props.Mode;	

		if (Mode == "Entry") {
			var result = await Pick5GetTeams(league);

			if (result && result.data) {
				PickFromTeams = result.data;

				Bracket1 = PickFromTeams.filter(team => team.Bracket == 1);
				Bracket2 = PickFromTeams.filter(team => team.Bracket == 2);
				Bracket3 = PickFromTeams.filter(team => team.Bracket == 3);
				Bracket4 = PickFromTeams.filter(team => team.Bracket == 4);
				Bracket5 = PickFromTeams.filter(team => team.Bracket == 5);

				AddActions();

				var myPicks = await GetUserPick5Entries(league);

				if (myPicks && myPicks.data) {
					EntryList = myPicks.data.Entries;
					selectUserPicksByEntry();
				}

				showPick5 = true;
			};
		}
		else {
			var result = await GetPick5Results(league);
			
			if (result && result.data) {
				standings = result.data;

				showTeamList = new Array(standings.length - 1).fill(false);

				showPick5 = true;
			}
		}

		showSpinner.set(false);
	});

	var showTeams = function(indx) {
		showTeamList[indx] = !showTeamList[indx];
	}

	var getTeams = function(user) {
		var teamList = [];
		
		var entry = standings.find(x => x.UserName == user);

		teamList.push({ Team: entry.Team1, Wins: entry.Wins1 });
		teamList.push({ Team: entry.Team2, Wins: entry.Wins2 });
		teamList.push({ Team: entry.Team3, Wins: entry.Wins3 });
		teamList.push({ Team: entry.Team4, Wins: entry.Wins4 });
		teamList.push({ Team: entry.Team5, Wins: entry.Wins5 });
		return teamList;
	}

</script>

<div>
	{#if showPick5}
		<br/>
		{#if Mode == "Entry"}
			<!-- svelte-ignore a11y-label-has-associated-control -->
			<div class="col-12 eug-inline" style="background-color: lightgray; border-radius: 5px;">
				<div class="col-12" style="margin: 20px 0 20px 0">
					<label class="eug-cost-lbl" style="">Cost: $10 Entry
						<button class="ms-auto eug-info-btn" on:click={() => { showRules = true }} title="Rules">
							<Fa icon={ faInfo }/>
						</button>
					</label>
				</div>
				<div class="d-flex eug-talign-left">
					<span>
						<label class="eug-grid-hdr">Bracket 1</label>
						<EugGrid bind:gridRowsList={Bracket1} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
					</span>
					<span>
						<label class="eug-grid-hdr">Bracket 2</label>
						<EugGrid bind:gridRowsList={Bracket2} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
					</span>
					<span>
						<label class="eug-grid-hdr">Bracket 3</label>
						<EugGrid bind:gridRowsList={Bracket3} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
					</span>
					<span>
						<label class="eug-grid-hdr">Bracket 4</label>
						<EugGrid bind:gridRowsList={Bracket4} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
					</span>
					<span>
						<label class="eug-grid-hdr">Bracket 5</label>
						<EugGrid bind:gridRowsList={Bracket5} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
					</span>
				</div>
				<br/>
				<button class="btn btn-primary contest-btn" style="margin: 0 68px 10px;" disabled={ !has5Selected } on:click={ savePicks } >
					Save Picks
					{#if showBtnSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
			</div>
		{:else}
			<div class="eug-inline results-wrap">
				<div class="eug-inline eug-talign-left">
					<span>Standings</span>
					<div style="display: flex;"><span class="prop prop-header">UserName</span><span class="prop prop-header">Wins</span></div>
					{#each standings as row, indx}
						<div class="data-row" style="display: flex;">
							<button class="btn btn-primary row-btn" style="font-size: .8rem; padding: 10px 5px 10px 5px;" on:click = {() => showTeams(indx) }>
								{#if showTeamList[indx] == true}		
									<Fa icon={faChevronUp}/>
								{:else}
									<Fa icon={faChevronDown}/>
								{/if}
							</button>
							<span class="prop prop-row">{row.UserName}</span>
							<span class="prop prop-row prop-col-2">{row.Total}</span>
						</div>
						{#if showTeamList[indx] == true}
							<div id="teamList{row.UserName}" class="prop-row-data-list">
								<div style="display: flex;"><span class="prop row-header expand-header">Team</span><span class="prop row-header">Wins</span></div>
								{#each getTeams(row.UserName) as team}
									<div class="row-data expand-row" style="display: flex;">
										<span class="prop prop-row">{team.Team}</span>
										<span class="prop prop-row prop-col-2">{team.Wins}</span>
									</div>
								{/each}
							</div>
						{/if}
					{/each}
				</div>
			</div>

		{/if}
	{/if}
</div>

{#if showRules}
	<Modal bind:isOpen={ showRules } title="13 Run Rules" msgID="2" />
{/if}

<style>
	.eug-cost-lbl {
		text-align: center;
		color: #375a7f; 
		width: 100%;
		padding-left: 20px;
	}

	.eug-info-btn {
		float: right;
		border: none;
		background-color: transparent;
		margin-right: 10px;
	}

	.row-btn {
		border-radius: 0px !important;
		padding: 6px 5px 6px !important;
		border-right: 1px solid var(--bs-body-bg);
	}

	.prop {
		width: 260px;
		text-align: left;
		padding: 6px 0 6px 12px;
	}

	.prop-header, .row-header {
		width: 260px;
		text-align: left;
		background-color: darkgray;
		border-right: 1px solid var(--bs-body-bg);
		padding: 6px 0 6px 12px;
		color: white;
		font-weight: bold;
		background-color: #375a7f;
		border-bottom: 1px solid var(--bs-body-bg);
	}

	.prop-header:first-of-type {
		padding-left: 34px;
	}

	.expand-header {
		width: 235px;
	}

	.expand-header:first-of-type {
		padding-left: 10px;
	}

	.prop-row {
		width: 235px;
		border-right: 1px solid var(--bs-body-bg);
	}

	.data-row, .row-data {
		background-color: lightgray;
		color: #375a7f;
		border-bottom: 1px solid var(--bs-body-bg);
	}

	.data-row:nth-of-type(odd) {
		background-color: darkgray;
		color: white;
	}

	.prop-col-2 {
		width: 260px;
	}

	.prop-row-data-list {
		margin-left: 25px;
	}

	.expand-row:last-of-type {
		margin-bottom: 10px;
	}

	.results-wrap {
		border-radius: 6px;
		background-color: lightgray;
		/*width: 60%;*/
		padding: 10px;
		color: #375a7f;
	}

</style>