﻿<script>

	import { Dropdown,DropdownItem,DropdownMenu,DropdownToggle } from 'sveltestrap';

	export let itemList = [];
	export let selItem = "";
	export let labelText = "";

	export let selectTrigger = function(item) {
		setItem(item);
	}

	let setItem = function(item){
		selItem = item;
	}

</script>

<label class="dd-label" for="AY_DD">{labelText}</label>

<Dropdown>
	{#if (itemList.length == 0) }
		<DropdownToggle caret>Empty</DropdownToggle>
	{:else}
		{#if (selItem == "") }
			<DropdownToggle caret>Select</DropdownToggle>
		{:else}
			<DropdownToggle caret>{selItem}</DropdownToggle>
		{/if}
	{/if}
	<DropdownMenu>
		{#each itemList as item}
			<DropdownItem on:click = { selectTrigger(item.Key), setItem(item.Key) }>{ item.Value }</DropdownItem>
		{/each}
	</DropdownMenu>
</Dropdown>

<style>
	.dd-label {
		display: inline-block;
		margin-right: 4px;
	}

	* :global(.dropdown) {
		display: inline-block;
	}

	* :global(.dropdown-toggle) 
	{
		min-width: 84px;
		padding-right: 5px;
	}
</style>