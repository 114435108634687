﻿<script>
	
	import { onMount } from 'svelte';
	import { user } from '../shared/store';
	import EugGrid from './shared/EugGrid.svelte';
	import { SiteSettings, showSpinner } from '../shared/store';
	import { RunGameSelectTeam, RunGameGetMyTeams, RunGameGetAllTeams } from '../shared/utils';
	import { Spinner } from 'sveltestrap';

	let league = "NFL";
	//let season = new Date().getFullYear(); //todo: figure this out for history
	let Mode = "";
	let showAll = false;

	let showBtnSpinner = false;
	let show13Run = false;
	let hasTeam = false;

	let cols = [{Name: 'Team', Hidden: false}];
	let myTeams = [];	

	let allTeamCols = [{Name: 'Team', Hidden: false}, {Name: 'UserName' }, { Name: "Winnings" }];
	let allTeams = [];	

	let gridActions = { "Actions": [] };

	async function GetTeams() {
		var result = await RunGameGetMyTeams(league);

		if (result && result.data && result.data.length > 0)
		{
			myTeams = result.data;
			hasTeam = true; 
		}

		show13Run = true;
		showSpinner.set(false);
	}

	async function GetTeam() {
		showSpinner.set(true);

		var result = await RunGameSelectTeam(league);

		if (result && result.data) {
			GetTeams();
		}
	}

	async function GetAllTeams() {
		var result = await RunGameGetAllTeams(league);

		if (result && result.data && result.data.length > 0) {
			allTeams = result.data;
		}

		show13Run = true;
		showAll = true;
		showSpinner.set(false);
	}

	onMount(async () => {
		Mode = $SiteSettings.NFL.Games[1].Props.Mode;

		if (Mode == "Entry") {
			GetTeams();
			if ($user.isAdmin) {
				GetAllTeams();
			}
		}
		else {
			if ($user.isAdmin) {
				gridActions.Actions.push({ "ColName": "Week Winnings", "Control": "Add1333Week", "Type": "Control", "League": league });
			}
			GetAllTeams();
		}
	});

</script>

<div>
	{#if show13Run}
		{#if Mode == "Entry"}
			<div class="col-6 eug-inline" style="background-color: lightgray; border-radius: 5px;">
				<p style="color: #375a7f;">$18 Entry
					<br/>If your team scores exactly 33 points you wim
					<br/>Payout based on # of entries and last winner(s)
					<br/>Contest locks on Wednesday before week 2 starts
				</p>
				<div class="eug-inline eug-talign-left">
					<EugGrid bind:gridRowsList={ myTeams } gridColsList={cols} tableHeader="Your Teams" />
				</div>
				<br />
				<button class="btn btn-primary contest-btn" style="margin: 0 68px 10px;" on:click={ GetTeam } >
					{#if hasTeam}
						Select Additional Team
					{:else}
						Select Team
					{/if}
					{#if showBtnSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
			</div>
		{/if}
		{#if showAll}
			<div class="col-6 eug-inline" style="background-color: lightgray; border-radius: 5px;">
				<div class="eug-inline eug-talign-left" style="padding-top: 20px;">
					<EugGrid bind:gridRowsList={ allTeams } gridColsList={ allTeamCols } gridActionsList={gridActions.Actions} />
				</div>
			</div>
		{/if}
	{/if}
</div>

<style>

</style>