﻿<script>

	import { Table, Button, Label } from 'sveltestrap';
    import AdjRecord from '../AdjRecord.svelte';
	import Add1333Week from '../Add1333Week.svelte';
	import Fa from 'svelte-fa';
	
	export let theme = 'dark';
	export let showGridOnEmpty = false;
	export let gridRowsList = [];
	export let gridColsList = [];
	export let gridActionsList = [];
	export let tableHeader = "";
	export let tableStyleCols = 'col-12';

</script>

{#if showGridOnEmpty || gridRowsList.length > 0 }
	<div class="{tableStyleCols} eug-table-wrap" style="margin: auto;">
		<div class="row eug-table">
			{#if tableHeader != ""}
				<div class="m-2 grid-header-panel">
					<Label>{tableHeader}</Label>
				</div>
			{/if}
			<span class={ tableHeader == "" ? "no-header" : "" }>
			<Table {theme} striped bordered hover>
				<thead>
					<tr>
						{#each gridColsList as colItem}
							{#if (typeof colItem === 'object')}
								{#if ('Hidden' in colItem && colItem.Hidden === true)}
									<th class="eug-hidden">{colItem.Name}</th>
								{:else}
									{#if ('DisplayAs' in colItem )}
										<th>{colItem.DisplayAs}</th>
									{:else}
										<th>{colItem.Name}</th>
									{/if}
								{/if}
							{:else}
								<th>{colItem}</th>
							{/if}
						{/each}
						{#each gridActionsList as colItem}
							<th class="ay-center">{colItem.ColName}</th>
						{/each}
					</tr>
				</thead>
				<tbody>
					{#each gridRowsList as rowItem, rowIndex (rowIndex)}
						<tr>
							{#each gridColsList as colItem}
								{#if (typeof colItem === 'object')}
									{#if ('Hidden' in colItem && colItem.Hidden === true)}		
										<td class="eug-hidden" style="vertical-align: middle">{rowItem[colItem.Name]}</td>
									{:else}
										<td style="vertical-align: middle;">{rowItem[colItem.Name]}</td>
									{/if}
								{:else}
									<td style="vertical-align: middle;">{rowItem[colItem]}</td>
								{/if}
							{/each}
							{#each gridActionsList as colItem}
								{#if colItem.Type == "Button"}
									<td class="ay-center">
										{#if colItem.Name }
											<Button class="me-2" on:click = { colItem.Function(colItem, rowItem, rowIndex) }>
												<span>{colItem.Name}</span>	
											</Button>
										{/if}
										{#if colItem.Icon}
											<Button class={colItem.Class} style="font-size: .8rem; padding: 2px 5px 2px 5px" on:click = { colItem.Function(colItem, rowItem, rowIndex) }>
												<Fa icon={colItem.Icon}/>
											</Button>
										{/if}
									</td>
								{/if}
								{#if colItem.Type == "CheckBox"}
									<td class="ay-center" style="min-width: 60px;">
										<input type="checkbox" class="checkbox" value={colItem.Name} on:click = { colItem.Function(colItem, rowItem, rowIndex) } />
									</td>
								{/if}
								{#if colItem.Type == "Radio"}
									<td class="ay-center" style="min-width: 60px;">
										{#if colItem.Checked(colItem, rowItem) }
											<input type="radio" name={ colItem.GroupName(rowItem) } checked="true" on:click={ colItem.Function(colItem, rowItem, rowIndex) } />
										{:else}
											<input type="radio" name={ colItem.GroupName(rowItem) } on:click={ colItem.Function(colItem, rowItem, rowIndex) } />
										{/if}
									</td>
								{/if}
								{#if colItem.Type == "Control"}
									<td class="ay-center">
										{#if colItem.Control == "AdjWin"}
											<AdjRecord StartValue={rowItem["W"]} Type={colItem.Control} League={rowItem["League"]} Team={rowItem["Team"]} />
										{/if}
										{#if colItem.Control == "AdjLoss"}
											<AdjRecord StartValue={rowItem["L"]} Type={colItem.Control} League={rowItem["League"]} Team={rowItem["Team"]} />
										{/if}
										{#if colItem.Control == "Add1333Week"}
											<Add1333Week League={ colItem.League } Team={rowItem["Team"] } />
										{/if}
									</td>
								{/if}
							{/each}
						</tr>
					{/each}
				</tbody>
			</Table>
			</span>
		</div>
	</div>
{/if}

<style>
	.eug-table-wrap {
		border-radius: 6px;
		background-color: lightgray;
	}

	table thead tr {
		background-color: #375a7f;
		font-weight: bold;
	}

	table tbody tr {
		color: black ;
		padding: 2px;
	}

	table thead tr th, table tbody tr td {
		padding: 0.2rem 0.5rem !important;
		text-wrap: nowrap;
	}

	table thead tr th.eug-hidden, table tbody tr td.eug-hidden {
		display:none !important;
	}

	div.eug-table {
		margin: 0 8px;
	}

	.row > * {
		padding-right: 0px;
		padding-left: 0px;
	}

	div.grid-footer-panel {
		width: 100%;
	}

	div.ay-float-right {
		float: right !important;
	}

	* :global(.ay-label) {
		margin-right:5px ;
	}

	.dd-label {
		margin-right: 4px;
	}

	* :global(input) {
		display: inline-block;
		width:60%;
	}

	* :global(.ay-grid-rows) {
		max-width: 50px;
	}

	* :global(button.me-2) {
		margin-top: -3px;
	} 

	td.ay-center {
		text-align: center;
		max-width: 90px;
	}

	* :global(td.ay-center button.me-2) {
		margin-right: 0px !important;
	} 

	.grid-header-panel {
		height: 20px;
		color: #375a7f;
	}

	.no-header {
		padding-top: 8px;
	}
	
	* :global(.table-striped > tbody > tr:nth-of-type(odd) > *) {
		font-weight: bold;
		background-color: darkgrey;
	}

	* :global(.table-striped > tbody > tr:nth-of-type(even):hover) {
		background-color: lightgray !important;
		color: #375a7f !important;
	}
</style>