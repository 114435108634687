﻿<script>

	import { onMount } from 'svelte';	
	import Fa from 'svelte-fa';
	import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
	import { UpdateStandings } from '../shared/utils';
	import { UpdatedTeam } from '../shared/store';
	
	export let StartValue = 0;
	export let League = "";
	export let Team = "";
	export let Type = "";

	let currentValue = 0;
	
	function addOne() {
		currentValue = currentValue + 1;
	}

	async function saveRecord() {
		var teamData = await UpdateStandings(League, Team, Type, parseInt(currentValue.toString()));
		UpdatedTeam.set(teamData);
	}

	let saveRow = function() {
		saveRecord();	
	}

	onMount(async () => {
		currentValue = StartValue;
	});
</script>

<div class="input-group td-control">
	<input class="form-control" style="font-size: .8rem;" maxlength=3 type=numeric bind:value={currentValue} />
	<button class="btn btn-secondary" style="font-size: .8rem; padding: 10px 5px 10px 5px;" on:click = { addOne }>
		<Fa icon={faPlus}/>
	</button>
	<!-- button divider -->
	<div class="border border-left border-dark m-0 mt-1 mb-1"></div>
	<button class="btn btn-secondary" style="font-size: .8rem; padding: 10px 5px 10px 5px;" on:click = { saveRow }>
		<Fa icon={faSave}/>
	</button>
</div>

<style>
	div.td-control {
		max-width: 96px;
		min-width: 96px;
		margin-left: -4px;
	}
</style>
