﻿<script>

	import Fa from 'svelte-fa';
	import { faSave } from '@fortawesome/free-solid-svg-icons';
	import { Update1333 } from '../shared/utils';
	import { UpdatedTeam } from '../shared/store';
	
	export let Week = 0;
	export let Winnings = 0;
	export let League = "";
	export let Team = "";

	async function saveRecord() {
		var teamData = await Update1333(League, Team, parseInt(Week.toString()), parseFloat(Winnings.toString()));
		UpdatedTeam.set(teamData);
	}

	let saveWeek = function() {
		saveRecord();	
	}
</script>

<div class="input-group td-control">
	<input class="form-control" style="font-size: .8rem;" maxlength="2" type=numeric bind:value={ Week } />
	<input class="form-control" style="font-size: .8rem;" maxlength="2" type=numeric bind:value={ Winnings } />
	<button class="btn btn-secondary" style="font-size: .8rem;" on:click = { saveWeek }>
		<Fa icon={faSave}/>
	</button>
</div>

<style>
	div.td-control {
		max-width: 120px;
		min-width: 120px;
		margin-left: -4px;
	}
</style>
