﻿<script>
	
	import { onMount } from 'svelte';
	import EugGrid from './shared/EugGrid.svelte';
	import { SiteSettings, showSpinner } from '../shared/store';
	import { OverUnderGetTeams, GetUserOUEntries, GetOUResults, SaveOU } from '../shared/utils';
	import { Spinner } from 'sveltestrap';
	import Modal from './shared/Modal.svelte';
	import Swal from 'sweetalert2';
	import Fa from 'svelte-fa';
	import { faChevronDown, faChevronUp, faRemove, faInfo } from '@fortawesome/free-solid-svg-icons';

	export let league;
	//let season = new Date().getFullYear(); //todo: figure this out for history
	let Mode = "";
	
	let PickFromTeams = [];
	let EntryList = []; //used for more than 1 entry per user
	let SelectedEntry = 1
	let selectedTeams = [];
	let has6Selected = false;
	let showBtnSpinner = false;
	let showOverUnder = false;
	let showRules = false;

	let standings = [];
	let showTeamList = [];
	
	let pickCols = [{Name: 'ID', Hidden: true}, {Name: 'Team', Hidden: false}, {Name: 'OU'}];

	let gridActions = { "Actions": [] };
	function AddActions() {
		//Example of a Button Action - Keep for reference
		gridActions.Actions.push({ "ColName": "Over", "Type": "Radio", "GroupName": getGroupName, "Checked": isChecked, "Function": checkTeam });
		gridActions.Actions.push({ "ColName": "Under", "Type": "Radio", "GroupName": getGroupName, "Checked": isChecked, "Function": checkTeam });
		gridActions.Actions.push({ "ColName": "Clear", "Type": "Button", "Icon": faRemove, "Class": "btn btn-secondary", "Function": removeOption });
	};

	function isChecked(action, returnData) {
		if (!(!!selectedTeams.find(x => x.ID == returnData.ID)))
			return false;

		var selTeam = selectedTeams.find(x => x.ID == returnData.ID);

		if (action.ColName == 'Over')
			return selTeam.Over == 1
		else
			return selTeam.Over == 0
	};
	
	function checkTeam(action, returnData, rowIndx) {
		var alreadySelected = false;
		var selTeam = selectedTeams.find(x => x.ID == returnData.ID);
			alreadySelected = selTeam != null

		if (selectedTeams.length == 6 && !alreadySelected) {
			Swal.fire({
				text: "Clear a previous pick to make this one (6 max)",
				icon: 'info',
				color: 'var(--bs-body-color)',
				background: 'var(--bs-dark)',
				confirmButtonColor: 'var(--bs-primary)',
			})

			selectedTeams = selectedTeams.filter(x => x.ID != returnData.ID);

			document.querySelector('input[name="' + getGroupName(returnData) + '"]:checked').checked = false;
			return;
		}
		
		if (!alreadySelected)
			selTeam	= {};

		selTeam.ID = returnData.ID;
		selTeam.Team = returnData.Team;
		selTeam.Over = action.ColName == "Over" ? 1 : 0;

		if (!alreadySelected)
			selectedTeams.push(selTeam);

		has6Selected = selectedTeams.length == 6;
	};
	
	function getGroupName(rowData) {
		return league + rowData.ID;
	};

	function removeOption(action, returnData, rowIndx) { 
		selectedTeams = selectedTeams.filter(x => x.ID != returnData.ID);

		has6Selected = selectedTeams.length == 6;

		document.querySelector('input[name="' + getGroupName(returnData) + '"]:checked').checked = false;
	};

	async function savePicks() {
		showSpinner.set(true);
		await SaveOU(league, SelectedEntry, selectedTeams);
		showSpinner.set(false);

		Swal.fire({
			text: "Picks Saved!",
            icon: 'info',
            color: 'var(--bs-body-color)',
            background: 'var(--bs-dark)',
            confirmButtonColor: 'var(--bs-primary)',
		})
	}

	function selectUserOUPicksByEntry() {

		var selTeams = EntryList[SelectedEntry - 1].Picks;
			
		for (var i = 0; i <  selTeams.length; i++)
		{
			var selTeam = {};
			selTeam.ID = selTeams[i].ID;
			selTeam.Team = selTeams[i].Team;
			selTeam.Over = selTeams[i].Over == "1" ? 1 : 0;

			selectedTeams.push(selTeam);
		}
	}

	onMount(async () => {
		showSpinner.set(true);
		if (league == "MLB")
			Mode = $SiteSettings.MLB.Games[2].Props.Mode;
		else
			Mode = $SiteSettings.NFL.Games[2].Props.Mode;

		if (Mode == "Entry") {
			var result = await OverUnderGetTeams(league);

			if (result && result.data) {
				PickFromTeams = result.data;

				AddActions();

				var myPicks = await GetUserOUEntries(league);

				if (myPicks && myPicks.data) {
					EntryList = myPicks.data.Entries;
					selectUserOUPicksByEntry();
				}

				showOverUnder = true;
			};
		}
		else {
			var result = await GetOUResults(league);
			
			if (result && result.data) {
				standings = result.data;

				showTeamList = new Array(standings.length - 1).fill(false);

				showOverUnder = true;
			}
		}

		showSpinner.set(false);
	});

	var showTeams = function(indx) {
		showTeamList[indx] = !showTeamList[indx];
	}

	var getTeams = function(user) {
		var teamList = [];
		
		var entry = standings.find(x => x.UserName == user);

		teamList.push({ Team: entry.Team1, Choice: entry.Choice1, OU: entry.OU1, Pace: entry.Pace1, Pts: entry.Pts1 });
		teamList.push({ Team: entry.Team2, Choice: entry.Choice2, OU: entry.OU2, Pace: entry.Pace2, Pts: entry.Pts2 });
		teamList.push({ Team: entry.Team3, Choice: entry.Choice3, OU: entry.OU3, Pace: entry.Pace3, Pts: entry.Pts3 });
		teamList.push({ Team: entry.Team4, Choice: entry.Choice4, OU: entry.OU4, Pace: entry.Pace4, Pts: entry.Pts4 });
		teamList.push({ Team: entry.Team5, Choice: entry.Choice5, OU: entry.OU5, Pace: entry.Pace5, Pts: entry.Pts5 });
		teamList.push({ Team: entry.Team6, Choice: entry.Choice6, OU: entry.OU6, Pace: entry.Pace6, Pts: entry.Pts6 });
		return teamList;
	}
</script>

<div>
	{#if showOverUnder}
		<br/>
		{#if Mode == "Entry"}
			<!-- svelte-ignore a11y-label-has-associated-control -->
			<div class="col-6 eug-inline" style="background-color: lightgray; border-radius: 6px;">
				<div class="col-12" style="margin: 20px 0 20px 0">
					<label class="eug-cost-lbl" style="">Cost: $10 Entry
						<button class="ms-auto eug-info-btn" on:click={() => { showRules = true }} title="Rules">
							<Fa icon={ faInfo }/>
						</button>
					</label>
				</div>
				<br/>
				<div class="eug-inline eug-talign-left">
					<EugGrid bind:gridRowsList={PickFromTeams} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
				</div>
				<div class="eug-inline eug-talign-left">
					<EugGrid bind:gridRowsList={PickFromTeams} gridColsList={pickCols} gridActionsList={gridActions.Actions} />
				</div>
				<br/>
				<button class="btn btn-primary contest-btn" style="margin: 0 68px 10px;" disabled={ !has6Selected } on:click={ savePicks } >
					Save Picks
					{#if showBtnSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
			</div>
		{:else}
			<div class="eug-inline results-wrap">
				<div class="eug-inline eug-talign-left">
					<span>Standings</span>
					<div style="display: flex;"><span class="prop prop-header">UserName</span><span class="prop prop-header">Points</span></div>
					{#each standings as row, indx}
						<div class="data-row" style="display: flex;">
							<button class="btn btn-primary row-btn" style="font-size: .8rem; padding: 10px 5px 10px 5px;" on:click = {() => showTeams(indx) }>
								{#if showTeamList[indx] == true}		
									<Fa icon={faChevronUp}/>
								{:else}
									<Fa icon={faChevronDown}/>
								{/if}
							</button>
							<span class="prop prop-row prop-col-1">{row.UserName}</span>
							<span class="prop prop-row prop-col-2">{row.Total}</span>
						</div>
						{#if showTeamList[indx] == true}
							<div id="teamList{row.UserName}" class="prop-row-data-list">
								<div style="display: flex;">
									<span class="prop row-header expand-header">Team</span>
									<span class="prop row-header SmallCell">Choice</span>
									<span class="prop row-header SmallCell">OU</span>
									<span class="prop row-header SmallCell">Pace</span>
									<span class="prop row-header SmallCell">Points</span>
								</div>
								{#each getTeams(row.UserName) as team}
									<div class="row-data expand-row" style="display: flex;">
										<span class="prop prop-row">{team.Team}</span>
										<span class="prop prop-row SmallCell">{team.Choice}</span>
										<span class="prop prop-row SmallCell">{team.OU}</span>
										<span class="prop prop-row SmallCell">{team.Pace}</span>
										<span class="prop prop-row SmallCell">{team.Pts}</span>
									</div>
								{/each}
							</div>
						{/if}
					{/each}
				</div>
			</div>
		{/if}
	{/if}
</div>

{#if showRules}
	<Modal bind:isOpen={ showRules } title="Over/Under Rules" msgID="3" />
{/if}

<style>
	.eug-cost-lbl {
		text-align: center;
		color: #375a7f; 
		width: 100%;
		padding-left: 20px;
	}

	.eug-info-btn {
		float: right;
		border: none;
		background-color: transparent;
		margin-right: 10px;
	}

	.row-btn {
		border-radius: 0px !important;
		padding: 6px 5px 6px !important;
		border-right: 1px solid var(--bs-body-bg);
	}

	.prop {
		width: 260px;
		text-align: left;
		padding: 6px 0 6px 12px;
	}

	.prop-header, .row-header {
		width: 260px;
		text-align: left;
		background-color: darkgray;
		border-right: 1px solid var(--bs-body-bg);
		padding: 6px 0 6px 12px;
		color: white;
		font-weight: bold;
		background-color: #375a7f;
		border-bottom: 1px solid var(--bs-body-bg);
	}

	.prop-header:first-of-type {
		padding-left: 34px;
	}

	.expand-header {
		width: 214px;
	}

	.expand-header:first-of-type {
		padding-left: 10px;
	}

	.prop-row {
		width: 215px;
		border-right: 1px solid var(--bs-body-bg);
	}

	.data-row, .row-data {
		background-color: lightgray;
		color: #375a7f;
		border-bottom: 1px solid var(--bs-body-bg);
	}

	.data-row:nth-of-type(odd) {
		background-color: darkgray;
		color: white;
	}

	.prop-col-1 {
		width: 235px;
	}

	.prop-col-2 {
		width: 260px;
	}

	.prop-row-data-list {
		margin-left: 25px;
	}

	.expand-row:last-of-type {
		margin-bottom: 10px;
	}

	.results-wrap {
		border-radius: 6px;
		background-color: lightgray;
		/*width: 60%;*/
		padding: 10px;
		color: #375a7f;
	}

	.SmallCell {
		max-width: 70px;
	}

</style>