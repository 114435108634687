﻿<script>
	import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'sveltestrap';
	import Fa from 'svelte-fa';
	import { faXmark } from '@fortawesome/free-solid-svg-icons';

	export let title = "";
	export let isOpen = false;
	export let msgID = 0;

</script>

{#if isOpen}
	<div class="eug-modal-wrapper">
		<Card class="border border-secondary align-self-center eug-card">
			<CardHeader class="d-flex flex-row align-items-center" >
				<CardTitle>{title}</CardTitle>
				<Button class="ms-auto" size="sm" on:click={ () => { isOpen = false; } }>
					<Fa icon={faXmark}/>   
				</Button>
			</CardHeader>
			<CardBody class="card-body">
				<slot name="content">
					{#if msgID == 1}
						<span style="color: white; text-align: left;">
							<ul>
								<li>Clicking the button will select a random team</li>
								<li>Your team scores exactly 13 runs, you win</li>
								<li>Winnings are determined by how many other teams
									have scored 13 and the total # of entries</li>
								<li>Each week a $1, per entry, is added to the pot</li> 
								<li>Week 1 and All Star week will be more than 7 days</li> 
							</ul>
						</span>
					{/if}
					{#if msgID == 2}
						<span style="color: white; text-align: left;">
							<ul>
								<li>Pick 1 team from each bracket</li>
								<li>Combine for the most wins this season</li>
								<li>Payout: Based on # of Entries</li>
							</ul>	
						</span>
					{/if}
					{#if msgID == 3}
						<span style="color: white; text-align: left;">
							<ul>
								<li>Pick Over or Under for 6 Teams</li>
								<li>1 point for each Win/Over or Loss/Under</li>
								<li>-1 point for each Loss/Over or Win/Under</li>
								<li>Combine for the most points in the season</li>
								<li>Payout: Based on # of Entries</li>
							</ul>
						</span>
					{/if}
				</slot>
			</CardBody>
			<CardFooter class="d-flex align-items-center justify-content-end">
				<slot name="buttons"></slot>
			</CardFooter>
		</Card>
	</div>
{/if}

<style>
	.eug-modal-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}

	* :global(.eug-card) 
	{
		max-width:420px; 
		margin: 5% auto; 
		max-height: 90%;
	}
</style>