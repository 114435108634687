﻿<script>
	
	import { onMount } from 'svelte';
	import { user } from '../shared/store';
	import EugGrid from './shared/EugGrid.svelte';
	import { SiteSettings, showSpinner } from '../shared/store';
	import { RunGameSelectTeam, RunGameGetMyTeams, RunGameGetAllTeams } from '../shared/utils';
	import { Button, Spinner } from 'sveltestrap';
    import Modal from './shared/Modal.svelte';
	import Fa from 'svelte-fa';
	import { faInfo } from '@fortawesome/free-solid-svg-icons';

	let league = "MLB";
	//let season = new Date().getFullYear(); //todo: figure this out for history
	let Mode = "";
	let showAll = false;

	let showBtnSpinner = false;
	let show13Run = false;
	let hasTeam = false;
	let showRules = false;

	let cols = [{Name: 'Team', Hidden: false}]; 
	let myTeams = [];	

	let allTeamCols = [{Name: 'Team', Hidden: false}, {Name: 'UserName' }, { Name: "Winnings", DisplayAs: '$$$' }];
	let allTeams = [];	

	let gridActions = { "Actions": [] };

	async function GetTeams() {
		var result = await RunGameGetMyTeams(league);

		if (result && result.data && result.data.length > 0)
		{
			myTeams = result.data;
			hasTeam = true; 
		}

		show13Run = true;
		showSpinner.set(false);
	}

	async function GetTeam() {
		showSpinner.set(true);

		var result = await RunGameSelectTeam(league);

		if (result && result.data) {
			GetTeams();
		}
	}

	async function GetAllTeams() {
		var result = await RunGameGetAllTeams(league);

		if (result && result.data && result.data.length > 0) {
			allTeams = result.data;
		}

		show13Run = true;
		showAll = true;
		showSpinner.set(false);
	}

	onMount(async () => {
		Mode = $SiteSettings.MLB.Games[1].Props.Mode;

		if (Mode == "Entry") {
			GetTeams();
			if ($user.isAdmin) {
				GetAllTeams();
			}
		}
		else {
			if ($user.isAdmin) {
				gridActions.Actions.push({ "ColName": "Week Winnings", "Control": "Add1333Week", "Type": "Control", "League": league });
			}
			GetAllTeams();
		}
	});

</script>

<div>
	{#if show13Run}
		<br/>
		{#if Mode == "Entry"}
			<div class="col-6 eug-inline" style="background-color: lightgray; border-radius: 5px;">
				<div class="col-12">	
					<!-- svelte-ignore a11y-label-has-associated-control -->
					<label class="eug-cost-lbl" style="">Cost: $26 Per Team
						<button class="ms-auto eug-info-btn" on:click={() => { showRules = true }} title="Rules">
							<Fa icon={ faInfo }/>
						</button>
					</label>
				</div>
				<div class="eug-inline eug-talign-left">
					<EugGrid bind:gridRowsList={ myTeams } gridColsList={cols} tableHeader="Your Team(s)" />
				</div>
				<br />
				<button class="btn btn-primary contest-btn" style="margin: 0 68px 10px;" on:click={ GetTeam } >
					{#if hasTeam}
						Select Additional Team
					{:else}
						Select Team
					{/if}
					{#if showBtnSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
			</div>
		{/if}
		{#if showAll}
			<div class="col-8 eug-inline" style="background-color: lightgray; border-radius: 5px;">
				<div class="eug-inline eug-talign-left" style="padding-top: 20px;">
					<EugGrid bind:gridRowsList={ allTeams } gridColsList={ allTeamCols } gridActionsList={gridActions.Actions} />
				</div>
			</div>
		{/if}
	{/if}
</div>

{#if showRules}
	<Modal bind:isOpen={ showRules } title="13 Run Rules" msgID="1" />
{/if}

<style>
	.eug-cost-lbl {
		text-align: center;
		color: #375a7f; 
		width: 100%;
		padding-left: 20px;
	}

	.eug-info-btn {
		float: right;
		border: none;
		background-color: transparent;
		margin-right: 10px;
	}
</style>