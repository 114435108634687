﻿<script>
	
	import { onMount } from 'svelte';
    import { Label } from 'sveltestrap';
	import { MLBStandings } from '../shared/utils';
	import EugGrid from './shared/EugGrid.svelte';
	import { UpdatedTeam, user } from '../shared/store';

	let showActions = false;

	$: {
		showActions = $user.isAdmin;
		addActions();
	}

	$: {
		if ($UpdatedTeam && Object.keys($UpdatedTeam).length > 0) {
			switch ($UpdatedTeam.League.toString())
			{
				case "AL East":
					ALErows = [...UpdateRecord(ALErows)];
					break;
				case "AL Central":
					ALCrows = [...UpdateRecord(ALCrows)];
					break;
				case "AL West":
					ALWrows = [...UpdateRecord(ALWrows)];
					break;
				case "NL East":
					NLErows = [...UpdateRecord(NLErows)];
					break;
				case "NL Central":
					NLCrows = [...UpdateRecord(NLCrows)];
					break;
				case "NL West":
					NLWrows = [...UpdateRecord(NLWrows)];
					break;
				default:
					break;
			}		

			UpdatedTeam.set({});
		}
	}

	function UpdateRecord(leagueGroup){
		if ($UpdatedTeam.Type == 'AdjWin')	
			leagueGroup.find(row => { return row.Team.toString() === $UpdatedTeam.Team.toString() }).W = $UpdatedTeam.Value;
		else
			leagueGroup.find(row => { return row.Team.toString() === $UpdatedTeam.Team.toString() }).L = $UpdatedTeam.Value;
		return leagueGroup;
	}

	let cols = ['Team','W','L']

	//MLB
	let ALErows = [];
	let ALCrows = [];
	let ALWrows = [];
	let NLErows = [];
	let NLCrows = [];
	let NLWrows = [];

	let gridActions = { "Actions": [] };

	function addActions() {
		gridActions = { "Actions": [] };
		if (showActions) {
			gridActions.Actions.push({ "ColName": "Add W", "Control": "AdjWin", "Type": "Control" });
			gridActions.Actions.push({ "ColName": "Add L", "Control": "AdjLoss", "Type": "Control" });
		}
	}

	onMount(async () => {
		await GetMLBStandings();

		addActions();
	});

	async function GetMLBStandings() {
		if (ALErows.length > 0)
			return;

		var result = await MLBStandings();

		if (result && result.data) {

			var rows = result.data;
			if (rows.length > 0) {
				cols = ['Team','W','L']
			}
			
			ALErows = rows.filter((row) => row.League.toString() === "AL East");
			ALCrows = rows.filter((row) => row.League.toString() === "AL Central");
			ALWrows = rows.filter((row) => row.League.toString() === "AL West");
			NLErows = rows.filter((row) => row.League.toString() === "NL East");
			NLCrows = rows.filter((row) => row.League.toString() === "NL Central");
			NLWrows = rows.filter((row) => row.League.toString() === "NL West");
		}
	}
</script>
	<div>
		<div class="eug-inline">
			<Label style="width: 100%; text-align: center;">AL</Label>
			<div class="eug-inline eug-talign-left standings-card">
				<span class="grid-card">
					<EugGrid bind:gridRowsList={ALErows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={ALCrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={ALWrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
			</div>
		</div>

		<div class="eug-inline">
			<Label style="width: 100%; text-align: center;">NL</Label>
			<div class="eug-inline eug-talign-left standings-card">
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NLErows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NLCrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NLWrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
			</div>
		</div>
	</div>
<style>
	.standings-card {
		min-width: 250px;
	}

	.grid-card  {
		display: flex;
		margin-bottom: 6px;
	}
</style>