﻿import Cookies from 'js-cookie';
import { writable } from 'svelte/store';

// app wide modal spinner, usage: showSpinner.set(true/false);
export const showSpinner = writable(false);

// app wide boolean set when AYModal isOpen, usage: isModalOpen.set(true/false);
export const isModalOpen = writable(false);
export const isLoggedIn = writable(false);

/* user:    
    persistent, stored in cookie
    contains things like: access_token, employeeID, employeeName, company, plant 
*/
export const user = writable(getCookieValue("user", {}));
user.subscribe(val => { setCookieValue("user", val); });

export const UpdatedTeam = writable({});
export const SiteSettings = writable({});

function setCookieValue(key, val) {
    // store the value in a base64 encoded string
    var base64 = btoa(JSON.stringify(val));
    Cookies.set(key, base64, { expires: 7 })
}

function getCookieValue(key, defaultValue) {
    // try to get the value from the cookie or return the default value
    var value = "";
    var cookieValue = Cookies.get(key) ?? "";
    try {
        cookieValue = atob(cookieValue);
        value = JSON.parse(cookieValue);
    } catch { }
    return value ?? defaultValue;
}