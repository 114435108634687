﻿<script>

	import { onMount } from 'svelte';
	import { GetUsers, SaveUserBalance } from '../shared/utils';
	import Fa from 'svelte-fa';
	import { faXmark, faSave } from '@fortawesome/free-solid-svg-icons';
	
	let UsersList = [];

	onMount(async () => {
		var rtnResult = await GetUsers();

		if (rtnResult && rtnResult.data) {
			UsersList = rtnResult.data.Users;
		}
	});

	async function removeUser(userID) {
		var thisUser = UsersList.find(x => x.UserID == userID);
	}

	async function saveBalance(userID) {
		var thisUser = UsersList.find(x => x.UserID == userID);
		var saveData = {
			UserID: userID,
			Balance: thisUser.Balance
		}
		await SaveUserBalance(saveData)
	}
</script>
<div>
	{#each UsersList as user}
		<div class="input-group">
			<button class="btn btn-dark eug-btn-reverse" on:click={() => removeUser(user.UserID) } >
				<Fa icon={faXmark}/>
			</button>
			<span class="btn btn-secondary eug-span-bubble">{user.UserName}</span>
			<span class="btn btn-secondary eug-span-bubble">{user.FirstName}</span>
			<span class="btn btn-secondary eug-span-bubble">{user.LastName}</span>
			<span class="btn btn-secondary eug-span-bubble-long">{user.Email}</span>
			<input class="btn btn-secondary eug-span-bubble" type="number" bind:value={user.Balance}/>
			<button class="btn btn-dark eug-btn-reverse" on:click={() => saveBalance(user.UserID) } >
				<Fa icon={faSave}/>
			</button>
		</div>
	{/each}
</div>


<style>
	.eug-span-bubble {
		min-width: 120px;
		padding: 6px 10px 6px 10px;
		text-align: left;
	}

	.eug-span-bubble-long {
		min-width: 280px;
		padding: 6px 10px 6px 10px;
		text-align: left;
	}

	.eug-btn-reverse {
		background-color: white;
		color: black;
	}
</style>