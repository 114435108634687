﻿<script>
	import { Input, Spinner } from 'sveltestrap';
	import Swal from 'sweetalert2'
	import { user, isLoggedIn } from '../shared/store';
	import { Authenticate, SetUser } from '../shared/utils';
	import Fa from 'svelte-fa';
	import { faArrowRightToBracket, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

	let userName = '';
	let password = '';

	let showSpinner = false;
	let showLoggedIn = false;

	function keyDown(e) {
		if (e.key == 'Enter')
		{
			if (!showLoggedIn && userName != '' && password != '')
				okLoginClicked();
		}
	}

	async function okLoginClicked() {
		showSpinner = true;
		var result = await Authenticate(userName, password);
		showSpinner = false;

		if (result) {
			SetUser(result);
			showLoggedIn = true;
			isLoggedIn.set(true);
		} else {
			Swal.fire({
				text: 'User Name or Password invalid',
				icon: 'error',
				timer: 2000,
				color: '#e1e1e1',
				background: '#444444',
				confirmButtonColor: '#375A7F'
			});
		}
	}

	async function LogOutClicked() {
        user.set({ userID: 0, firstName: "", lastName: "", userName: '', emai: '', access_token: '' });
		userName = '';
		password = '';
		showLoggedIn = false;

		isLoggedIn.set(false);
    } 
</script>

<div style="display: inline-block;"> 
	{#if !showLoggedIn}
		<div style="display: inline-block;"> 
			<Input bind:value={userName} on:keydown={keyDown} placeholder="User Name" />
		</div>
		<div style="display: inline-block;"> 
			<Input type="password" bind:value={password} on:keydown={keyDown} placeholder="Password" />
		</div>
		<div style="display: inline-block; margin: 6px 8px 0 4px;"> 
			<button class="btn btn-primary eug-btn" tab-index="3" disabled={ userName == '' || password == '' || showSpinner } on:click={okLoginClicked}>
				<span class="eug-btn-icon-wrapper">
					<Fa icon={faArrowRightToBracket}/>
				</span>
				<span>Log In</span>
				{#if showSpinner}
					<Spinner class="ms-2" size="sm" color='light' />
				{/if}		
			</button>
		</div>
	{/if}
	{#if showLoggedIn}
		<div class="d-flex user-banner"> 
			<div class="username-div"> 
				<span class="username-lbl">Welcome Back {userName}</span>
			</div>
			<div class="d-flex"> 
				<button class="btn btn-primary" title="Log Out" tab-index="3" on:click={LogOutClicked}>
					<span>
						<Fa icon={faRightFromBracket}/>
					</span>
				</button>
			</div>
		</div>
	{/if}
</div>

<style>
	div.user-banner {
		margin-top: 4px;
		margin-right: 4px;
	}

	.username-div {
		background-color: #375a7f;
		text-align: center;
		padding: 0px 15px 0px 10px;
		display: inline-flex;
		margin: 0px -8px 0 -18px;
		vertical-align: middle;
		min-height: 38px;
		border: none;
	}

	.username-lbl {
		padding-top: 6px;
		color: white;
		
	}
</style>
