<script>
    import Home from './Home.svelte';
    import UserAdmin from './UserAdmin.svelte';
    import { Spinner, Button } from 'sveltestrap';    
    import { isLoggedIn, showSpinner, user } from '../shared/store';
    import Login from './Login.svelte';
    import EugModal from './shared/Modal.svelte'
    import { CheckUserName, RegisterUser, SetUser } from '../shared/utils';
    import Swal from 'sweetalert2';
    import Fa from 'svelte-fa';
	import { faXmark, faAddressCard, faSackDollar, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
    
	let submitted = false;

    let loading = false;
    let showRegister = false;
    let showAccount = false;
    let showRegisterBtn = false;
    let showAccountBtn = false;
    let showAdminActions = false;
	let showUsers = false;

    let firstName = "";
	let lastName = "";
    let userName = "";
	let password = "";
	let userEmail = "";
    let balance = 0;

    $: {
        loading = $showSpinner;
        showRegisterBtn = !$isLoggedIn;
        showAccountBtn = $isLoggedIn;
        showAdminActions = $isLoggedIn && $user.isAdmin;
        showUsers = showUsers && showAdminActions;
    }

    async function saveUser() {
        var userInfo = {};
        userInfo.firstName = firstName;
        userInfo.lastName = lastName;
        userInfo.userEmail = userEmail;
        userInfo.userName = userName;
        userInfo.password = password;

        var result = await RegisterUser(userInfo);

        if (result.hasOwnProperty('data'))
        {
            SetUser(result.data);
            Swal.fire("Registration Succesful").finally(showRegister = false);
        }
        else {
            Swal.fire("Registration failed");
        }
	}

    function checkUser() {
        checkUserName();
    }

    async function checkUserName() {
        var result = await CheckUserName(userName);
	}

    function showRegistration() {
        showRegister = true;
    }

    function showMyAccount() {
        firstName = $user.firstName;
        lastName = $user.lastName;
        userName = $user.userName;
        userEmail = $user.email;
        balance = $user.balance;

        showAccount = true;
    }

    function handleSubmit(e) {
        saveUser();
	}
</script>

<div class="login-section">
    <div style="display: inline-block; vertical-align: bottom; margin-right: 4px;"> 
        {#if showRegisterBtn}
			<button class="btn btn-primary eug-btn" on:click={ showRegistration }>
                <span class="eug-btn-icon-wrapper">
                    <Fa icon={faAddressCard}/>
                </span>
                <span>Register</span>
            </button>
        {:else}
            <button class="btn btn-primary" title="My Account" on:click={ showMyAccount }>
                <span class="eug-btn-icon-wrapper">
                    <Fa icon={faAddressCard}/>
                </span>
            </button>
        {/if}
	</div>
    <Login />
    {#if showAdminActions}
		<Button tabindex="3" color="primary" on:click={() => showUsers = true}>
			Users
		</Button>
	{/if}
</div>

<span class="triangle"></span>
<span class="logo">
    <div class="logo-line-1">Bet Your</div>
    <span class="logo-line-2">A<span class="logo-sack-dollar"><Fa icon={faSackDollar}/><Fa icon={faSackDollar}/></span></span>
    <div class="logo-dollar-bag"><Fa icon={faMoneyBill1Wave}/></div>
</span>

<div class="col-12 page">
    {#if showUsers}
	    <button class="btn btn-dark eug-btn-reverse" on:click={() => showUsers = false } >
		    <Fa icon={faXmark}/>
	    </button>	
        <UserAdmin />
    {:else}
        <Home />
    {/if}
    
    {#if $showSpinner}
        <div class="vh-100 w-100 d-flex position-absolute justify-content-center top-0 end-0 spinner">
            <Spinner class="ms-2 my-auto " size="lg" color='var(--bs-body-color)' />
        </div>
    {/if}
</div>

<form class:submitted on:submit|preventDefault={handleSubmit}>
    <EugModal bind:isOpen={ showRegister } title="Registration">
	    <div slot="content">
            <div class="eug-inline form-group">
                <div class="data-section-row">
                    <div class="data-section-label">First Name:</div>
                    <input class="form-control eug-form-control" bind:value={ firstName } required/>
                </div>
                <div class="data-section-row">
                    <div class="data-section-label">Last Name:</div>
                    <input class="form-control eug-form-control" bind:value={ lastName } required/>
                </div>
                <div class="data-section-row">
                    <div class="data-section-label">Email:</div>
                    <input class="form-control eug-form-control" type="email" bind:value={ userEmail } required/>
                </div>
                <div class="data-section-row">
                    <div class="data-section-label">User Name:</div>
                    <input id="username" class="form-control eug-form-control" bind:value={ userName } on:blur={ checkUser } required/>
                </div>
                <div class="data-section-row">
                    <div class="data-section-label">Password:</div>
                    <input class="form-control eug-form-control" type="password" bind:value={ password } required/>
                </div>
            </div>
	    </div>

	    <div slot="buttons">
		    <button class="me-1 btn btn-secondary" on:click={() => { showRegister = false }}>Cancel</button>
		    <button class="me-1 btn btn-secondary" on:click={ () => submitted = true }>Submit</button>
	    </div>
    </EugModal>
</form>

<EugModal bind:isOpen={ showAccount } title="Account">
	<div slot="content">
        <div class="eug-inline form-group">
            <div class="data-section-row">
                <div class="data-section-label">First Name:</div>
                <input class="form-control eug-form-control" bind:value={ firstName } disabled/>
            </div>
            <div class="data-section-row">
                <div class="data-section-label">Last Name:</div>
                <input class="form-control eug-form-control" bind:value={ lastName } disabled/>
            </div>
            <div class="data-section-row">
                <div class="data-section-label">Email:</div>
                <input class="form-control eug-form-control" type="email" bind:value={ userEmail } disabled/>
            </div>
            <div class="data-section-row">
                <div class="data-section-label">User Name:</div>
                <input type="text" class="form-control eug-form-control" maxlength="10" bind:value={ userName } disabled/>
            </div>
            <div class="data-section-row">
                <div class="data-section-label">Balance:</div>
                <input class="form-control eug-form-control" bind:value={ balance } disabled/>
            </div>
        </div>
	</div>

	<div slot="buttons">
		<button class="me-1 btn btn-secondary" on:click={() => { showAccount = false }}>Close</button>
	</div>
</EugModal>

<style global>
    :focus { outline: none; } input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    * :global(.form-control) 
	{
		display: inline-block;
		padding: 3px 10px 3px 10px;
        min-width: 190px;
	}

    .login-section {
        width: 100%;
        text-align: right;
        position: absolute;
        padding-right: 10px;
    }

    .triangle {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-right: 230px solid transparent;
        border-bottom: 230px solid #375a7f; /* Change color as needed */
        transform: rotate(90deg);
        z-index: -1;
    }

    .logo {
        position: absolute;
        top: 0px;
        left: 20px;
    }

    .logo-line-1 {
        font-size: 36px;
    }

    .logo-line-2 {
        font-size: 40px;
    }

    .logo-dollar-bag {
        font-size: 28px;
    }

    .logo-sack-dollar {
        position: absolute;
        margin-top: 7px;
        font-size: 30px;
    }

    .page {
         padding: 50px 20px 0 20px; 
         text-align: center;
    }

    .emp-info-bubble {
        padding: 1px 10px 3px 10px;
        border: 1px solid white;
        background-color: #444;
        margin-right: 4px;
    }        
    .emp-info-bubble-label {
        color: black;
        margin-right: 3px;
    }        

    .eug-inline {
	    display: inline-block;
    }

    .eug-valign-top {
        vertical-align: top;
    }

    .eug-talign-left {
        text-align: left;
    }

    .eug-float-right {
        float: right;
    }

    .data-section-row {
		margin-bottom: 4px;
        display: inline-flex;
	}

    .data-section-inline {
        display: inline-block;
	}

	.data-section-label {
		min-width: 90px;
		text-align: right;
		display: inline-block;
		margin-right: 10px;
		vertical-align: top;
        margin-top: 2px;
	}

    .eug-form-control {
        min-width: 280px;
    }

    .eug-btn {
        width: 150px;
		margin: 0 4px;
		display: inline-flexbox;
		justify-content: center;
		white-space: nowrap;
		vertical-align: middle;
        box-shadow: none !important;
    }

    .eug-btn-icon-wrapper {
        background-color: white;
        color: #375A7F;
        border-radius: 6px;
        margin-right: 5px;
        padding: 0px 7px;
        float: left;
    }

    .eug-btn-icon-1 {
        padding-right: 0px;
    }

    .eug-btn-icon-2 {
        padding-left: 0px;
        margin-left: -6px;
    }

    .eug-grid-hdr {
        color: #375a7f;
        width: 100%;
        text-align: center;
    }
</style>
